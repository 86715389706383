import React from "react"
import { Link, navigate } from "gatsby"
import { ChevronDown as ChevronDownIcon } from "react-feather"

export default function Menu({ items, uri, location })
{
    const versions = require('../../docs/versions.json')

    const headerStyle = "text-gray-400 font-bold tracking-widest uppercase mb-2 mt-0"

    /**
     * Determine if the menu item is the currently active page.
     * 
     * @param {string} itemPath 
     * 
     * @return {boolean}
     */
    function activeMenuItem(itemPath) {
        // We'll remove any trailing slash in
        // case it's been given, to ensure
        // we can perform an exact match.
        return location.pathname.replace(/\/$/, "") === itemPath
    }

    return (
        <>
            <div className="mb-4">
                <label className={headerStyle} htmlFor="version">
                    Version
                </label>
                
                <div className="relative">
                    <select
                        value={uri}
                        name="version"
                        onChange={event => event.target.blur()}
                        onBlur={event => navigate(event.target.value)}
                        className="block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-lg leading-tight focus:outline-none"
                    >
                        {versions.map((selectableVersion, index) => {
                            return (
                                <option value={selectableVersion.path} key={index}>{selectableVersion.name}</option>
                            )
                        })}
                    </select>

                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <ChevronDownIcon className="fill-current h-4 w-4"/>
                    </div>
                </div>
            </div>

            {items.map((item, index) => {
                return (
                    <li className="mb-4" key={index}>
                        <p className={headerStyle}>{item.name}</p>
                        
                        {
                            item.items && 
                            <ul className="space-y-2">
                                {item.items.map((item, index) => {
                                    let activeClasses = activeMenuItem(item.path)
                                        ? 'rounded-lg bg-app-100 px-2 -ml-2'
                                        : '-ml-1';

                                    return (
                                        <li key={index} className={`p-1 ${activeClasses}` }>
                                            <Link className="text-gray-500" to={`${item.path}`} activeClassName="text-app-400 font-medium">
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </li>
                )
            })}
        </>
    )
}