import React from "react"
import { graphql } from "gatsby"
import DocumentLayout from "../layouts/document"

export default function Page({data, location}) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    
    return (
        <DocumentLayout title={frontmatter.title} location={location} headings={markdownRemark.headings}>
            <div
                className="content mb-8"
                dangerouslySetInnerHTML={{ __html: html }}
            />

            <div className="text-center text-gray-400 mt-16">
                <span>Last Updated: {frontmatter.date}</span>
            </div>
        </DocumentLayout>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(
            frontmatter: {
                slug: { eq: $slug }
                draft: { ne: true }
            }
        ) {
            html
            headings {
                id
                depth
                value
            }
            frontmatter {
                slug
                date(formatString: "MMMM DD, YYYY")
                title
            }
        }
    }
`