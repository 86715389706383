import React, { useState } from "react"
import AppLayout from "./app"
import Menu from "../components/menu"
import { useMatch } from "@reach/router"
import SlideOver from "../transitions/slide_over"
import FadeInOut from "../transitions/fade_in_out"
import OnThisPage from "../components/table_of_contents"
import { Menu as MenuIcon, X as TimesIcon } from "react-feather"

export default function Document({headings, title, location, children})
{
    const { uri, version } = useMatch('/docs/:version/*')

    const menuItems = require(`../../docs/${version}/menu.json`)

    const [isToggled, setToggled] = useState(false)

    const toggleMenu = () => setToggled(!isToggled)

    return (
        <AppLayout isShowingMenu={isToggled} title={title} location={location} container={true} reporting={true}>
            {/* Mobile navigation menu. */}
            <SlideOver show={isToggled} className="z-20 fixed inset-0 w-full bg-white lg:hidden overflow-auto">
                <aside className="px-6 py-16 mt-16">
                    <ul className="list-none">
                        <Menu items={menuItems} uri={uri} location={location}/>
                    </ul>
                </aside>
            </SlideOver>

            <div className="flex flex-col lg:flex-row justify-center mx-4">
                {/* Desktop navigation menu. */}
                <aside className={`hidden lg:w-2/5 xl:w-1/5 lg:block border-none lg:border-solid lg:border-r lg:border-gray-200`}>
                    <ul className="list-none sticky mb-4 mr-4" style={{top: '7rem'}}>
                        <Menu items={menuItems} uri={uri} location={location}/>
                    </ul>
                </aside>

                {/* Documentation contents. */}
                <div className="lg:w-3/5 lg:mx-8 mb-16 pt-6 min-h-screen">
                    {children}
                </div>

                {/* Table of contents. */}
                <aside className="hidden xl:w-1/5 xl:block xl:border-solid xl:border-l xl:border-gray-200">
                    <ul className="list-none sticky mb-4 ml-4 pl-2" style={{top: '7rem'}}>
                        <OnThisPage headings={headings} location={location}/>
                    </ul>
                </aside>
        </div>

            {/* Mobile nav toggle. */}
            <div className="fixed bottom-0 right-0 z-40 p-6 lg:hidden">
                <button onClick={toggleMenu} className="p-3 text-white rounded-full shadow-lg bg-app-600">
                    { isToggled
                        ? <FadeInOut><TimesIcon className="h-6 w-6"/></FadeInOut>
                        : <FadeInOut><MenuIcon className="h-6 w-6"/></FadeInOut>
                    }
                </button>
            </div>
        </AppLayout>
    )
}
