import React from "react"
import { Transition } from "@headlessui/react"

export default function FadeInOut(props)
{
    return (
        <Transition
            {...props}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        />
    )
}

FadeInOut.defaultProps = {
    appear: true,
    show: true,
}