import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

export default function TableOfContents(props)
{
    const [activeAnchor, setActiveAnchor] = useState(props.location.hash ?? '')

    useEffect(() => {
        const observer = createObserver()

        getObservervableElements().forEach((el) => {
            observer.observe(el)
        })

        return () => {
            observer.disconnect()
        }
    })

    /**
     * Create a new intersection observer.
     * 
     * @return {IntersectionObserver}
     */
    function createObserver() {
        return new window.IntersectionObserver(observerCallback, {
            threshold: 1
        })
    }

    /**
     * Get all of the observable headers on the document.
     * 
     * @return {NodeListOf}
     */
    function getObservervableElements() {
        return document.querySelectorAll(
            '.content h1, .content h2, .content h3'
        )
    }
    
    /**
     * Updates the currently focused header on the page.
     * 
     * @param {array} entries 
     * 
     * @return {void}
     */
    function observerCallback(entries) {
        // We will filter the observers entries to only those that are
        // intersecting with the pages root. If there are multiple,
        // the first will be set as the active anchor on the page.
        let entry = entries.find(entry => entry.isIntersecting)

        if (! entry) {
            return
        }

        setActiveAnchor('#' + entry.target.id)

        if (window.history.replaceState) {
            window.history.replaceState(null, null, '#' + entry.target.id);
        }
    }

    /**
     * Determine if the heading is currently active.
     * 
     * @param {Object} heading 
     */
    function isActiveHeader(heading) {
        return activeAnchor === `#${heading.id}`
    }

    return (
        <>
            <li className="uppercase font-bold text-gray-400 tracking-widest mb-2">
                On this page
            </li>
            
            {/* We only want to list h1, h2, and h3 headings. */}
            {props.headings.filter(heading => heading.depth < 3).map((heading, index) => {
                let depthClass = {
                    1: "-ml-2",
                    2: "ml-0",
                    3: "ml-2"
                }

                let activeHeaderClass = isActiveHeader(heading)
                    ? 'rounded-lg bg-app-100 text-app-400'
                    : 'text-gray-500'
                
                return (
                    <li
                        key={index}
                        className={`${depthClass[heading.depth]} ${activeHeaderClass} p-2 text-sm transition duration-200 ease-in-out`}
                    >
                        <Link
                            to={`${props.location.pathname}#${heading.id}`}
                            onClick={() => setActiveAnchor(`#${heading.id}`)}
                            className="font-medium"
                        >
                            {heading.value}
                        </Link>
                    </li>
                )
            })}
        </>
    )
}