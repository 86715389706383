import React from "react"
import { Transition } from "@headlessui/react"

export default function SlideOver(props)
{
    return (
        <Transition
            {...props}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
        />
    )
}